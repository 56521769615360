<template>
  <div v-show="isActive">
    <div v-if="headerTitle && headerImage" class="tab-header">
      <BrandLogo :image="headerImage" />
      <div class="tab-headlines">
        <VueText color="grey-30" sizeLevel="3">Philip Morris</VueText>
        <VueText color="grey-40" sizeLevel="6">{{ headerTitle }}</VueText>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
export default {
  props: {
    name: { required: true },
    selected: { default: false },
    id: { required: true },
    headerTitle: { required: false },
    headerImage: { required: false },
    enabled: { default: true },
  },
  components: {
    VueText,
    BrandLogo,
  },
  data() {
    return {
      isActive: false,
    };
  },

  computed: {
    href() {
      return '#' + this.name.toLowerCase().replace(/ /g, '-');
    },
  },

  mounted() {
    this.isActive = this.selected;
  },
};
</script>
<style lang="scss" scoped>
.tab {
  &-header {
    display: flex;
    align-items: center;
    padding: palette-space-level('10') palette-space-level('20');
    border-bottom: 1px solid palette-color-level('grey', '15');
  }
  &-headlines {
    padding-left: palette-space-level('15');

    & > p {
      padding: palette-space-level('2');
    }
  }
}
</style>
