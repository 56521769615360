<template>
  <div>
    <div class="tabs">
      <ul>
        <li v-for="tab in tabs" :key="tab.name" :class="{ 'is-active': tab.isActive }">
          <div class="scroll-item" :id="`anc_${tab.id}`"></div>
          <BrandButton
            as="a"
            :size="sizes.xSmall"
            :href="tab.href"
            :outlined="!tab.isActive"
            @click="selectTab(tab)"
          >
            <VueText sizeLevel="3" weightLevel="4">
              {{ tab.name }}
            </VueText>
          </BrandButton>
        </li>
      </ul>
    </div>
    <div class="gtm-ad-slot" id="div-gpt-ad-1587047396774-0"></div>
    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  components: {
    BrandButton,
    VueText,
  },
  mixins: [gtmUtils],
  data() {
    return { tabs: [] };
  },
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
  },
  mounted() {
    this.$slots.default.forEach(item => {
      if (item.componentInstance.enabled) {
        this.tabs.push(item.componentInstance);
      }
    });
    this.pushAds('large');
    setTimeout(() => {
      let activeIndex = this.tabs.findIndex(item => item.isActive);
      this.scrollToActiveTab(this.tabs[activeIndex].id);
      this.$emit('selectedTab', activeIndex, this.tabs[activeIndex].name);
    }, 0);
  },
  methods: {
    scrollToActiveTab(tabId) {
      document.getElementById(`anc_${tabId}`).scrollIntoView();
    },
    selectTab(selectedTab) {
      this.tabs.forEach((tab, index) => {
        let $isActiveTab = tab.name == selectedTab.name;
        tab.isActive = $isActiveTab;
        if ($isActiveTab) {
          this.$emit('selectedTab', index, tab.name);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.tabs {
  width: 100%;
  overflow: auto;
  padding: 0 palette-space-level('10');
  background-color: palette-color-level('grey', '10');
  border-bottom: 1px solid palette-color-level('grey', '15');
  ul {
    display: flex;
    list-style: none;

    li {
      z-index: 1;
      padding: palette-space-level('20') palette-space-level('8');

      a {
        color: inherit;
      }
    }
  }
}
.scroll-item {
  position: relative;
  top: -20px;
}
</style>
