<template>
  <div class="content-layout-fixer">
    <VueContentHolder id="divManageHomeWrapper" :padding="[0, 0, 20, 0]">
      <VueTabs ref="tabs" @selectedTab="setActiveTab">
        <VueTab
          v-for="(item, index) in tabItems"
          :selected="activeTabIndex === index"
          :key="index"
          :name="item"
          :id="index"
        >
          <component :is="getComponent(index)" v-if="activeTabIndex === index" />
        </VueTab>
      </VueTabs>
    </VueContentHolder>
  </div>
</template>
<script>
import { ref } from 'vue';
import VueTabs from '@/components/shared/VueTabs/VueTabs.vue';
import VueTab from '@/components/shared/VueTab/VueTab.vue';
import TrackPreorderHistory from '../secure/manageMyBusiness/TrackPreorderHistory.vue';
import SaleTracking from '../secure/manageMyBusiness/SaleTracking.vue';
import TrackEarnings from '../secure/manageMyBusiness/TrackEarnings.vue';
import ScoreTracking from '../secure/manageMyBusiness/ScoreTracking.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import FollowProducts from '../secure/manageMyBusiness/FollowProducts.vue';

export default {
  components: {
    VueTabs,
    VueTab,
    TrackPreorderHistory,
    VueContentHolder,
    SaleTracking,
    ScoreTracking,
    TrackEarnings,
    FollowProducts,
  },
  setup() {
    const activeTabIndex = ref(0);

    const tabItems = ref([
      'SATIŞINI TAKİP ET',
      'KAZANCINI TAKİP ET',
      'ÜRÜNLERİNİ TAKİP ET',
      'PUANINI TAKİP ET',
      'ÖN SİPARİŞ GEÇMİŞİNİ TAKİP ET',
    ]);

    const getComponent = index => {
      const components = [
        'SaleTracking',
        'TrackEarnings',
        'FollowProducts',
        'ScoreTracking',
        'TrackPreorderHistory',
      ];
      return components[index];
    };

    const setActiveTab = index => {
      activeTabIndex.value = index;
    };

    return { setActiveTab, activeTabIndex, getComponent, tabItems };
  },
};
</script>
<style scoped lang="scss"></style>
